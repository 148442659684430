import React, { useState } from "react"
import "../Card/listingcard.scss"
import VizSensor from "react-visibility-sensor"
import { Link } from "gatsby"
import PreLoader from "./preloader.png"
const MonthlyListingCardAlter = props => {
  const [imgViz, setimgViz] = useState(false)
  const [imgVizdisplay, setimgVizdisplay] = useState(false)
  const url = `https://www.gofloaters.com/app/#/home/explore/bookingrequest/${props.spaceId}`
  var divStyleLoad = {
    height: "200px",
    backgroundImage: "url(" + PreLoader + ")",
    backgroundSize: "cover",
    WebkitTransition: "all", // note the capital 'W' here
    msTransition: "all", // 'ms' is the only lowercase vendor prefix
    borderRadius: "5px",
  }
  var divStyle = {
    height: "200px",
    backgroundImage: "url(" + props.listingImg + ")",
    backgroundSize: "cover",
    WebkitTransition: "all", // note the capital 'W' here
    msTransition: "all", // 'ms' is the only lowercase vendor prefix
    borderRadius: "5px ",
  }

  var str = ""
  const HourPrice = () => {
    return (
      <span className="priceTag">
        <b>₹ {props.priceHr}</b>/hour
      </span>
    )
  }

  const MonthlyPrice = () => {
    return (
      <span className="priceTag">
        <b>₹ {props.priceMonth}</b>/month
      </span>
    )
  }
  const DailyPrice = () => {
    return (
      <span className="priceTag">
        <b>₹ {props.priceDay}</b>/day
        <br></br>
      </span>
    )
  }
  const DailyFree = () => {
    return (
      <span className="priceTag">
        Daily: NA
        <br></br>
      </span>
    )
  }
  function decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value)
    }
    value = +value
    exp = +exp
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN
    }
    // Shift
    value = value.toString().split("e")
    value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)))
    // Shift back
    value = value.toString().split("e")
    return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
  }
  const ceil10 = (value, exp) => decimalAdjust("ceil", value, exp)
  return (
    <div className={"col-md-4  listingCardPaddingNew" + str}>
      <a href={props.Slug + "/"} target="_blank">
        <div
          className="listingCardNew"
          title={props.gftitle + " - " + props.OriginalName}
        >
          <div className="listingimagesnew">
            <VizSensor
              partialVisibility
              onChange={isVisible => {
                setimgViz(isVisible)
                if (!imgVizdisplay) {
                  setimgVizdisplay(isVisible)
                }
              }}
            >
              <div
                style={imgVizdisplay ? divStyle : divStyleLoad}
                className={props.online ? "" : "inactiveImg"}
              >
                {props.Rating ? (
                  <p className="cardRating">
                    <span>{props.Rating} / 5</span>
                  </p>
                ) : (
                  ""
                )}
                {/* {props.hasCovidSafeBadge ? (
                  <Link className="verifiedSpaceNew" to="/covid-safety-pledge/">
                    <img
                      src="https://assets.gofloaters.com/hearttick.svg"
                      className="verfiedBadge"
                      title="Verified Spaces"
                      alt="Verified Spaces"
                    ></img>
                    <p>Verified Safe</p>
                  </Link>
                ) : (
                  ""
                )} */}
              </div>
            </VizSensor>
          </div>
          <div className="listingDetailNew">
            <div className="row aligner">
              <div className="col-12">
                {props.spacetype != "Cafe" ? (
                  <h2 title={props.gftitle + " - " + props.OriginalName}>
                    {props.gftitle} - {props.OriginalName}{" "}
                  </h2>
                ) : (
                  ""
                )}
                {props.spacetype === "Cafe" ? (
                  <h2 title={props.gftitle - props.OriginalName}>
                    {props.title}{" "}
                  </h2>
                ) : (
                  ""
                )}
              </div>
              {/* <div className="col-3 text-right">
               
              </div> */}

              <div className="col-12">
                <p className="spaceaddress">
                  {" "}
                  {props.spacetype != "Cafe"
                    ? props.spaceDisplayName + " | "
                    : ""}{" "}
                  {props.spaceAddress}
                </p>
              </div>
            </div>

            <div className="row   minheight">
              <div className="col-12">
                <p
                  style={{
                    fontSize: "10px",
                    color: "#101010",
                    marginTop: "5px",
                  }}
                >
                  Starting from
                </p>
              </div>
              <div className="col-12">
                {props.monthPassAvailable && props.priceMonth !== " " ? (
                  <MonthlyPrice />
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 text-center cardButtonNew">
                {props.online ? (
                  <Link to={props.Slug + "/"}>
                    Explore <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </Link>
                ) : (
                  <a href={props.Slug + "/"} target="_blank">
                    Currently unavailable
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}
export default MonthlyListingCardAlter
